import VanillaTilt from 'vanilla-tilt'
import CONFIG from '@js/config'
import { trackSection } from '@/scripts/ga'
import '@js/app'

import scrollbar from '@js/scrollbar'
import './intro/intro'
import './intro/intro.scss'

import './featured_work/featured_work.scss'

import './about/about.scss'

import '@comp/business_category/business_category.scss'

import './featured_insight/featured_insight.scss'

import '@comp/animate_title/animate_title'
import '@comp/animate_title/animate_title.scss'

function fetchFeatured (cb) {
    $.when(
        $.ajax({
            type: 'GET',
            url: `${CONFIG.API_BASE}feature_work`,
            dataType: 'json'
        }),
        $.ajax({
            type: 'GET',
            url: `${CONFIG.API_BASE}feature_insights`,
            dataType: 'json'
        })
    ).then(function (msgWork, msgInsight) {
        cb(msgWork[0], msgInsight[0])
    }).fail(function (err1, err2) {
        console.log(err1)
        console.log(err2)
        location.href = '404.html'
    })
}

$(() => {
    trackSection('Homepage')
    $(document).on('click', '#contact-link-wrap', function () {
        location.href = 'contact.html'
    })

    $(document).on('click', '#about-link-wrap', function () {
        location.href = 'about.html'
    })

    fetchFeatured(function (resultWork, resultInsight) {
        let workHtml = ''

        resultWork.forEach((item, index) => {
            if (index > 2) {
                return
            }
            workHtml += `
                    <div class="fw-layout ${(index % 2) === 0 ? 'left' : 'right'}">
                        <a href="javascript:;" class="fw-main bg-hover">
                            <div class="fw-hover-scale">
                                <div class="fw-hover-tilt tilt-link">
                                    <div class="fw-main-bg" style="background-color: ${item.bg_color}"></div>
                                    <div data-parallax='-0.1' class="fw-main-img" style="background-image: url('${item.homepage_banner}')"></div>
                                </div>
                            </div>
                        </a>
                        <div class="container-fluid">
                            <div class="row elm-reveal">
                                <div class="column-item">
                                    <h1 data-parallax='-0.2' class="index-wrap">0${index + 1}</h1>
                                    <section class="slideup">
                                        <h6>Featured Work</h6>
                                    </section>
                                    <div class="sm-space"></div>
                                    <section class="slideup">
                                        <a href="javascript:;" class="ext title-link">
                                            <h2 class="text">${item.title}</h2>
                                        </a>
                                    </section>
                                    <div class="lsm-space"></div>
                                    <section class="slideup">
                                        <a href="work_detail.html?id=${item.id}"
                                          class="ext btn btn-primary track-btn"
                                          data-track="Feature Work #${item.title}">
                                            <b>VIEW CASE</b>
                                        </a>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lg-space"></div>
                `
        })

        $('#featured-work-list').html(workHtml)

        let insightHtml = ''

        resultInsight.forEach((insight, index) => {
            if (index > 2) {
                return
            }
            insightHtml += `
                    <div class="col-md-4 col-sm-7 col-xs-12 slideup">
                        <a href="insight_detail.html?id=${insight.id}" class="ext insight-block track-btn" data-track="Feature Insight #${insight.title}">
                            <div class="ib-img tilt-link">
                                <div class="ib-img-inner" style="background-image: url('${insight.cover}')"></div>
                            </div>
                            <div class="ib-content">
                                <b>${insight.category}</b>
                                <div class="xsm-space"></div>
                                <h5 class="ib-title">
                                    <span class="ellipsis">${insight.title}</span>
                                </h5>
                            </div>
                        </a>
                    </div>
                `
        })

        $('#featured_list').find('.row').html(insightHtml)

        VanillaTilt.init(document.querySelectorAll('.tilt-link'), {
            max: 2,
            speed: 600
        })

        scrollbar.init()
    })
})
