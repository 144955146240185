import { TweenLite, Expo } from 'gsap'

$(() => {
    let currentSlide
    let nextSlide
    let totalSlide = 3

    function introSlideLoop () {
        nextSlide = (currentSlide === totalSlide) ? 1 : currentSlide + 1
        TweenLite.to($('#iw-img-' + currentSlide + ' .iw-img-inner'), 1.5, {
            opacity: 1,
            scale: 1,
            ease: Expo.easeOut,
            onComplete: () => {
                TweenLite.to($('#iw-img-' + currentSlide + ' .iw-img-inner'), 1.5, {
                    opacity: 0,
                    delay: 4
                })
                TweenLite.to($('#iw-img-' + nextSlide + ' .iw-img-inner'), 1.5, {
                    opacity: 1,
                    scale: 1,
                    delay: 4,
                    ease: Expo.easeOut,
                    onComplete: () => {
                        TweenLite.set($('#iw-img-' + currentSlide + ' .iw-img-inner'), {
                            scale: 1.2
                        })
                        currentSlide = (currentSlide === totalSlide) ? 1 : currentSlide + 1
                        introSlideLoop()
                    }
                })
            }
        })
    }
    TweenLite.to($('.iw-cover'), 1, {
        x: 0,
        ease: Expo.easeInOut,
        delay: 0.5,
        onComplete: () => {
            TweenLite.to($('.iw-cover'), 1, {
                x: '100%',
                ease: Expo.easeInOut
            })
            $('.iw-img-animation').show()
            currentSlide = 1
            introSlideLoop()
        }
    })
})
